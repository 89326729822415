<!--
 * @Author: 1234567 648850689@qq.com
 * @Date: 2024-11-15 10:02:26
 * @LastEditors: 1234567 648850689@qq.com
 * @LastEditTime: 2025-01-10 11:37:44
 * @FilePath: \h5d:\java_web\dongjian_vue\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app" class="app-container" :class="{ 'PageStatus': PageStatus }">
    <!-- <p style="position: relative; z-index: 999;">当前页面宽度: {{ width }}px</p> -->
 
    <div class="app-opctiy" :class="{zoomWarning:isZoomed}">
      <div class="main-content">
     
          <router-view></router-view>
      </div>
    </div>
    
    
    
  </div>
</template>
 

<script setup>
  // filepath: /D:/java_web/dongjian_vue/src/views/securityServices/listBox/testTabTextOperatePage.vue
  import { ref,watch,inject,onMounted, onUnmounted } from 'vue';

const message = inject('message');
 
 
  import { debounce } from 'lodash';
  const width = ref(window.innerWidth);
  let PageStatus  = ref(false)
const originalResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends originalResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 100);
    super(callback);
  }
};

  const isZoomed = ref(false);

  const checkZoomLevel = () => {
  const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
  const hasScrollBar = document.documentElement.scrollHeight > window.innerHeight;
  isZoomed.value = zoomLevel === 149 && hasScrollBar;
  };

  const updateWidth = () => {
      width.value = window.innerWidth;
   
      console.log(width.value,'==============页面宽度')
    };
    if(width.value< 1410){
      PageStatus.value = true;
    }
    sessionStorage.setItem('pageWidth',width.value)
    console.log(width.value,'==============页面宽度')
  const appW = message;
  console.log(appW,'====================首页参数添加成功')
  watch(() => appW, (data) => {
    if(data){
      console.log(data,'====================首页参数添加成功')

    }

  });
  console.log(message,'===========首页==========')
  onMounted(() => {
  window.addEventListener('resize', checkZoomLevel);
  checkZoomLevel(); // 初始检查
  window.addEventListener('resize', updateWidth);
  });

  onUnmounted(() => {
  window.removeEventListener('resize', checkZoomLevel);
  window.removeEventListener('resize', updateWidth);
  });

 
</script>
<style>
/* body{
  padding-right: 0px !important;
  width: 100% !important;
  overflow: hidden !important;
}
#app{
  overflow: auto;
} */
/* 页面适配 */
.PageStatus,
.PageStatus .reportTabOperatePage .initializationBox{
  height: auto !important;
}
.PageStatus .ServicesContent .tipText{
  padding: 100px 0 35px 0;
}
.PageStatus.app-container1{
  height: 100vh !important;
}
.PageStatus .header-content,
.PageStatus .honeycombBg,
.PageStatus .w,
.PageStatus .content,
.PageStatus .footerContainer,
.PageStatus .SecurityServiceList-content,
.PageStatus .apkOperation-content,
.PageStatus .myOrder .orderBox{
  width: 1200px !important;
  /* min-width: 1200px; */
  /* transform: scale(0.5); */
}
.PageStatus .reportTabOperatePage .content{
  margin-left: 83px !important;
  width: 1117px !important;
}
.PageStatus .tableBox{
  width: 100% !important;
}
.PageStatus .honeycombBg{
  margin: 0 auto;
}
.PageStatus .main-hero img,
.PageStatus .listItem .item .images{
  transform: scale(0.8);

}
.PageStatus .listBox{
  height: auto !important;
  transform: scale(0.85);
}
.PageStatus .listBox .content{
 
  padding-top: 0 !important;
}
.PageStatus .isActive,
.PageStatus .genericSecurityServiceDetails,
.PageStatus .apkOperation,
.PageStatus .myOrder{
  height: auto !important;
}
.isActive,
.genericSecurityServiceDetails,
.apkOperation{
  min-height: 100vh;
}
.PageStatus .apkOperation-content .bar,
.PageStatus .genericSecurityServiceDetails .content{
  padding-top: 60px !important;
  
}
.PageStatus .apkOperation-content .operate,
.PageStatus .genericSecurityServiceDetails .content{
  transform: scale(0.9);

}

.PageStatus .genericSecurityServiceDetails .listItem .item3 .images,
.PageStatus .genericSecurityServiceDetails .listItem .item1 .images{
  transform: scale(0.6) !important;

}
.PageStatus .apkOperation .el-upload-dragger{
  /* height: 218px !important; */
}

.PageStatus .testBox{
  transform: scale(1);

}

.PageStatus .hero-section .title span{
  animation: textAnimation 1s forwards !important;
}
.PageStatus .hero-section p span{
  animation: textAnimation1 1s forwards !important;


}
@keyframes textAnimation {
  0% {
      opacity: 0;
      background: linear-gradient(to right,#974cf1, #233fac);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      transform: scale(1);
      font-size: 28px;

  }
  50% {
      opacity: .5;
      transform: scale(2.5);
      font-size: 28px;
  }
  100% {
      opacity: 1;
      background: linear-gradient(to right,#974cf1, #233fac);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      transform: scale(1);
      font-size: 28px;

  }
}
@keyframes textAnimation1 {
  0% {
      opacity: 0;
      background: linear-gradient(to right,#974cf1, #233fac);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      transform: scale(1);
      font-size: 52px;

  }
  50% {
      opacity: .5;
      transform: scale(1);
      font-size: 52px;
  }
  100% {
      opacity: 1;
      background: linear-gradient(to right,#974cf1, #233fac);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      transform: scale(1);
      font-size: 52px;

  }
}
.PageStatus .listBox .content .tabBox{
  left: -113px !important;
}
.PageStatus .identify .el-button{
  margin-left: 10px;
}
.PageStatus .audioTab .listItem{
  gap: 18px !important;
}
.PageStatus .audioTab .listItem .item,
.PageStatus .reportTab .listItem .item{
  width: 31% !important;
}
.PageStatus .audioTab .text,
.PageStatus .reportTab .text{
  padding-left: 0 !important;
  width: 90% !important;
}
.PageStatus .audioTab .advantages,
.PageStatus .audioTab .text,
.PageStatus .audioTab .btn,
.PageStatus .audioTab .listItem,
.PageStatus .reportTab .advantages,
.PageStatus .reportTab .text,
.PageStatus .reportTab .btn,
.PageStatus .reportTab .listItem{
  margin-left: 8% !important;

}
.PageStatus .listItem .item1 .images{
  margin-left: -24px;
}
.PageStatus .listItem .item2 .images{
  margin-left: -32px;
}
.PageStatus .listItem .item3 .images{
  margin-left: -30px;
}
.PageStatus .testAudioTab .content,
.PageStatus .imagesTabOperatePage .content{
  margin: 0 0 20px 83px;
  width: 654px !important;
}

.PageStatus .testBox .content .tabBox{
  left: -95px !important;
}
</style>
<style>
body {
    font-family: 'Inter', "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

html, body,div,p,span,ul,li,h1{
  margin: 0;
  padding: 0;
  border:0;
}
.zoomWarning {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffcc00;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  z-index: 1000;
}
.el-upload-list{
  display: none;
}

.el-upload-dragger:hover
{
  border-color:none;
  border: dashed 1px #409eff !important;
  border-radius: 11px;

}

.el-upload-dragger{
  border: solid 0.5px #c9cdd4;
  border-radius: 11px;
}
.app-container {
  height: 100vh;
  text-align: center;
  color: #2c3e50;
  /* background: url('./assets/background.png') no-repeat;  */
 
}

.app-opctiy{
 
  /* background-size: cover; */
 
}
/* 支付弹框-支付成功之后隐藏X */
.closeTime .el-dialog__headerbtn{
  display: none;
}

.router-view {
  padding: 20px;
}
/* 隐藏默认滚动条 */
::-webkit-scrollbar {
  width: 0px; /* 滚动条宽度 */
}

/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 轨道背景色 */
  border-radius: 10px; /* 轨道圆角 */
}

/* 自定义滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #6076c6, #a3bffa); /* 滑块渐变色 */
  border-radius: 10px; /* 滑块圆角 */
  border: 2px solid #f1f1f1; /* 滑块边框 */
}

/* 当鼠标悬停在滚动条上时改变滑块颜色 */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #6076c6, #92a8ff); /* 滑块悬停渐变色 */
}

/* 自定义滚动条拇指的阴影 */
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* 滑块阴影 */
}

/* 自定义滚动条拇指的边框 */
::-webkit-scrollbar-thumb {
  border: 3px solid transparent; /* 滑块透明边框 */
  background-clip: content-box; /* 背景裁剪 */
}

</style>


<style>

/* 支付弹框 */
.orderPayment{
  position: absolute;
  top:16px;
  left: 21px;
  width: 98px;
  height: 25px;
  background: url('./assets/securityServices/images/order_icon.png') no-repeat;
  background-size: 100% 100%
}
.chooseAnotherPaymentMethod .bar{
  position: absolute;
  top:16px;
  left: 21px;
  width: 138px;
  height: 20px;
  cursor: pointer;
  background: url('./assets/securityServices/images/order_icon1.png') no-repeat;
  background-size: 100% 100%
}
.rowItem{
  margin: 47px 0 0 28px;
  text-align: left;
  font-size: 14px;
   
}
.rowItem p{
  margin-bottom: 25px;
}
.rowItem span:nth-child(1){
  color:#16191f;
}
.rowItem span:nth-child(2){
  color:#6b7785;
}
.paymentMethod{
  position: relative;
    margin: 16px;
    padding: 29px 27.5px;
    border-radius: 4px;
    background: #f7f8fa;
}
.paymentMethod-title{
  text-align: left;
  font-size: 16px;
  color: #16191f;
}
.paymentMethod-tab{
  display: flex;
  gap: 13px;
  margin-top: 16px;
}
.paymentMethod-tab div{
  display: flex;
  width: 162px;
  height: 61px;
  border: 1px solid #e5e6eb;
  border-radius: 4px;
  background: url('./assets/securityServices/images/order_logo.png') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.paymentMethod-tab .active{
  border-color: #2f54eb;
  background: url('./assets/securityServices/images/order_logo_bg.png') no-repeat;
  background-size: 100% 100%; 
}
.paymentMethod-tab div:nth-child(2){
  background: url('./assets/securityServices/images/order_logo1.png') no-repeat;
  background-size: 100% 100%;
}
.paymentMethod-tab div:nth-child(3){
  background: url('./assets/securityServices/images/order_logo2.png') no-repeat;
  background-size: 100% 100%;
}
.paymentMethod-tab div:nth-child(4){
  background: url('./assets/securityServices/images/order_logo3.png') no-repeat;
  background-size: 100% 100%;
}
.dialog-footer{
  display: flex;
  padding-top: 41px;
  text-align: left;
}
.dialog-footer .text,
.codeBox .codeBox .text1{
  flex: 1;
  font-size: 16px;
  color: #4e5969;
}
.dialog-footer .money b,
.codeBox .money b{

  color: #f53f3f;
}

.dialog-footer .money b:nth-child(1),
.codeBox .money b:nth-child(1){
    
    font-size: 20px;
}
.dialog-footer .money b:nth-child(2),
.codeBox .money b:nth-child(2){
    
  font-size: 32px;
}
 
.dialog-footer .btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120PX;
  height: 40PX;
  background: #f53f3f;
  border-radius: 4PX;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.chooseAnotherPaymentMethod{
  padding-bottom: 122px;
}
.chooseAnotherPaymentMethod .text1{
  margin: 73px 0 0 214px;
  width: 100%;
  font-size: 12px;
  color: #6b7785;
}
.chooseAnotherPaymentMethod .codeBox{
  display: flex;
}
.chooseAnotherPaymentMethod .code{
  position: relative;
  margin-left:185px;
  width: 192px;
  height: 224px;
  background: url('./assets/securityServices/images/code.png') no-repeat;
  background-size: 100% 100%; 
}
.chooseAnotherPaymentMethod .codeText{
  margin: 20px 0 0 62px;
}
.chooseAnotherPaymentMethod .code .imageSrc{
  display: block;
  margin: 0 auto;
  margin-top: 18px;
  width: 167px;
  height: 167px;
}
.chooseAnotherPaymentMethod .code .zfsb{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 193px;
  background: url('./assets/my/zfsb.png') no-repeat;
  background-size: 100% 100%; 
}
.chooseAnotherPaymentMethod .orderAmount{
  margin: 30px 0 0 56px;
}

.chooseAnotherPaymentMethod .text2{
  margin-top: 14px;
  font-size: 14px;
  color: #16191f;
}
/* 支付成功页 */
.success .state{
  display: flex;
}
.success .state .bg{
  margin: 76px 0 0 275px;
  width: 64px;
  height: 64px;
  background: url('./assets/securityServices/images/success.png') no-repeat;
  background-size: 100% 100%; 
}
.success .state .text3{
  margin: 85px 0 0 24px;
  font-size: 32px;
  color:#16191f
}
.success .text4{
  margin: 23px 0 121px 250px;
  font-size: 14px;
  color:#16191f
}
.success .text4 span{
  cursor:pointer;
  color:#2f54eb;
  text-decoration: underline;
}
.success .viewBtn{
  margin: 0 auto;
  margin-bottom:60px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:131px;
  height:31px;
  color:#16191f;
  border-radius:4px;
  border: solid 1px #16191f;
  cursor: pointer;
}
 
.timeLeft2{
  position: absolute;
  top: 12px;
  right: 21px;
  color: #16191f;
  font-size: 14px;
}

</style>