/*
 * @Author: 1234567 648850689@qq.com
 * @Date: 2024-11-15 10:02:26
 * @LastEditors: 1234567 648850689@qq.com
 * @LastEditTime: 2025-01-09 10:33:41
 * @FilePath: \h5d:\java_web\dongjian_vue\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { ElDialog } from 'element-plus'
// 引入中文文件
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
 

document.title = '锐眼洞见';
// 设置 Dialog 组件的默认属性
ElDialog.props.lockScroll.default = false
const pinia = createPinia();
const app = createApp(App);

 

//设置中文
app.use(ElementPlus,{
    locale: zhCn,
});
// // 禁用鼠标滚轮缩放

app.use(pinia);
app.use(router);
app.mount('#app');

 