/*
 * @Author: 1234567 648850689@qq.com
 * @Date: 2024-11-15 10:02:26
 * @LastEditors: 1234567 648850689@qq.com
 * @LastEditTime: 2024-12-27 09:47:28
 * @FilePath: \h5d:\java_web\dongjian_vue\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/homePage/home.vue"),
  
  },
  {
    path: '/securityServices',
    name: 'securityServices',
    component: () => import("@/views/securityServices/securityServices.vue"),
 
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: () => import("@/views/ServicesPage.vue"),
  
  },
  {
    path: '/myorder',
    name: 'myOrder',
    component: () => import("@/views/myOrder/myOrder.vue"),
    
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import("@/views/userAgreement.vue"),
    
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import("@/views/privacyPolicy.vue"),
    
  },
  {
    path: '/productServiceAgreement',
    name: 'productServiceAgreement',
    component: () => import("@/views/productServiceAgreement.vue"),
    
  },
  {
    path: '/SecurityServiceList',
    name: 'SecurityServicelist',
    component: () => import("@/views/genericSecurityService/SecurityServiceList.vue"),
    
  },
  {
    path: '/genericSecurityServiceDetails',
    name: 'genericSecurityServiceDetails',
    component: () => import("@/views/genericSecurityServiceDetails/genericSecurityServiceDetails.vue"),
    
  },
  {
    path: '/apkOperation',
    name: 'apkOperation',
    component: () => import("@/views/apkOperation/apkOperation.vue"),
    
  },
  {
    path: '/apkReinforceDetails',
    name: 'apkReinforceDetails',
    component: () => import("@/views/apkReinforceDetails/apkReinforceDetails.vue"),
   
  },
  {
    path: '/apkReinforceOperation',
    name: 'apkReinforceOperation',
    component: () => import("@/views/apkReinforceOperation/apkReinforceOperation.vue"),
    
  },
  {
    path: '/vulnerabilityScanningDetails',
    name: 'vulnerabilityScanningDetails',
    component: () => import("@/views/vulnerabilityScanningDetails/vulnerabilityScanningDetails.vue"),
    
  },
  {
    path: '/vulnerabilityScanningOperate',
    name: 'vulnerabilityScanningOperate',
    component: () => import("@/views/vulnerabilityScanningOperate/vulnerabilityScanningOperate.vue"),
    
  },
  {
    path: '/voiceprintComparisonOperation',
    name: 'voiceprintComparisonOperation',
    component: () => import("@/views/voiceprintComparisonOperation/voiceprintComparisonOperation.vue"),
    
  }
  
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.path === '/' || to.path === '/securityServices' || to.path === '/myOrder') {
    sessionStorage.removeItem('genericSecurityService');
  }
  next();
});

export default router;

 